// user account actions
angular.module('sq.jobs.events.service',
['sq.services.socketIO', 'sq.jobs.model', 'sq.jobs.tracker.service',
'sq.serverEvents.service',
'sq.jobs.values', 'sq.user.auth'])
.factory('jobEvents', jobEventsFactory);

function jobEventsFactory(jobTracker, Jobs, socketIO, jobValues, Auth, serverEvents, $log, $rootScope, $q) {
  const jE = {};

  //private events in addition to our server events
  const events = Object.assign({
    clear: 'clear',
    initialize: 'initialize',
    // Any job update
    update : 'update',
  }, jobValues.server.events);

  let allValidEvents = {};
  let allValidEventsArray = [];
  
  Object.keys(jobValues.server.events).forEach( (type) => {
    Object.keys(jobValues.server.events[type]).forEach( (innerType) => {
      allValidEvents[ jobValues.server.events[type][innerType] ] = 1;
      allValidEventsArray.push( jobValues.server.events[type][innerType] );
    });
  });

  const eventPrefix = 'sq.jobs.events.service:';
  jE.eventPrefix = eventPrefix;

  // Jobs may be deleted by a controller, but we want to send out a broadcast 
  // of this job change
  jE.deleteJob = _updateJob;
  // immutable
  Object.defineProperty(jE, 'events', {
    get: function() {
      return events;
    },
    enumerable: true,
  });

  jE.listen = listen;

  return jE;

  function _forward(state, data, jobUpdateCb) {
    if(!data) {
      $log.error('_forward requires data');
      return;
    }

    if (!state || !allValidEvents[state]) {
      $log.error('_forward called without state, or with unrecognized state', arguments);
      return;
    }

    const messageContainer = typeof data === 'string' ? JSON.parse(data) : data;

    // We don't force re-fetch (2nd argument force = false)
    // because this class may be called thousands of times per second
    jobTracker.getOneAsync(messageContainer._id, false).then((job) => {
      if(jobUpdateCb) {
        jobUpdateCb(job, messageContainer, (updatedJob) => {
          const [err, trackedJob] = jobTracker.trackJobUpdate(updatedJob);

          if(err) {
            $log.error(err);
            return;
          }

          $rootScope.$broadcast(eventPrefix + state, trackedJob);
        });
      } else {
        const [err, trackedJob] = jobTracker.trackJobUpdate(job);

        if(err) {
          $log.error(err);
          return;
        }

        $rootScope.$broadcast(eventPrefix + state, trackedJob);
      }
    });
  }

  function _setSocketIOlisteners(socket) {
    $log.debug('setting socket io listeners in jobs.events.service.js');

    // _removeListeners(socket);

    socket.on(events.annotation.submitted, (data) => {
      $log.debug('received annotation submitted event in jobs.events.service');
      _forward(events.annotation.submitted, data, _updateJob);
    });

    socket.on(events.annotation.started, (data) => {
      $log.debug('received annotation started event in jobs.events.service');
      _forward(events.annotation.started, data, _updateJob);
    });

    socket.on(events.annotation.failed, (data) => {
      $log.debug('received annotation failed event in jobs.events.service');
      _forward(events.annotation.failed, data, _updateJob);
    });

    socket.on(events.annotation.completed, (data) => {
      $log.debug('received annotation completed event in jobs.events.service');
      _forward(events.annotation.completed, data, _updateJob);
    });

    socket.on(events.deletion.completed, (data) => {
      $log.debug('received deletion completed event in jobs.events.service');
      _forward(events.deletion.completed, data, _updateJob);
    });

    //too expensive to broadcast maybe, so don't
    socket.on(events.annotation.progress, (data) => {
      // $log.debug('receieved annotation progress event', data);
      _forward(events.annotation.progress, data, _updateSubmissionProgress);
    });
    
    socket.on(events.searchIndex.submitted, (data) => {
      $log.debug('receieved searchIndex submitted event');
      _forward(events.searchIndex.submitted, data, _updateSearchData);
    });

    socket.on(events.searchIndex.started, (data) => {
      $log.debug('receieved searchIndex started event');
      _forward(events.searchIndex.started, data, _updateSearchData);
    });

    socket.on(events.searchIndex.progress, (data) => {
      // $log.debug('receieved searchIndex progress event', data);
      _forward(events.searchIndex.progress, data, _updateActiveSearchProgress);
    });

    socket.on(events.searchIndex.failed, (data) => {
      $log.debug('receieved searchIndex failed event');
      _forward(events.searchIndex.failed, data, _updateSearchData);
    });

    socket.on(events.searchIndex.completed, (data) => {
      $log.debug('receieved searchIndex completed event');
      _forward(events.searchIndex.completed, data, _updateSearchData);
    });
  }

  // could be dangerous; assumes only this servce listens to these events
  function _removeListeners(socket) {
    allValidEventsArray.forEach( (event) => {
      socket.removeAllListeners(event);
    });
  }

  // TODO: use something like Jobs.prototype.patch to make simpler, more graphql-like
  // approach to patching
  // TODO: better error handling
  function _updateJob(job, message, cb) {
    cb(message.data);
    return;
  }

  function _updateSubmissionProgress(job, message, cb) {
    job.updateSubmissionProgress(message.data);

    cb(job);
    return;
  }

  function _updateActiveSearchProgress(job, message, cb) {
    job.updateActiveSearchIndex(message.data);

    cb(job);
    return;
  }

  function _updateSearchData(job, message, cb) {
    job.updateSearchData(message.data);

    cb(job);
    return;
  }

  function listen() {
    // placing here to avoid circular dependency with jobs.tracker.service
    // (since  the initialize and clear methods are handled in results.service.js
    // this is inconsistent )
    $rootScope.$on(Auth.eventPrefix + 'loggedIn', () => {
      $log.debug('received loggedIn in jobs.events.service');

      onLogIn();
    });

    $rootScope.$on(Auth.eventPrefix + 'loggedOut', () => {
      onLogOutOrDown();
    });

    // TODO: not sure if these events are useful to us here
    // $rootScope.$on(serverEvents.eventPrefix + 'serverUp', () => {
    //   $log.debug('received serverUp in jobs.events.service');
    //   // onLogIn();
    // });

    // $rootScope.$on(serverEvents.eventPrefix + 'serverDown', () => {
    //   $log.debug('received serverDown in jobs.events.service');

    //   //TODO: Not sure what is appropriate to do here
    //   //Can call onLogOutOrDown();
    //   //For now, using http interceptor to buffer requests made while server
    //   //is down
    // });

    $rootScope.$on('sq.services.socketIO:unauthorized', (event, socket) => {
      $log.debug('unauthorized in job events');
      _removeListeners(socket);
    });

    $rootScope.$on('sq.services.socketIO:authenticated', (event, socket) => {
      _removeListeners(socket);
      _setSocketIOlisteners(socket);
    });

    $rootScope.$on('sq.services.socketIO:disconnected', (event, socket) => {
      $log.debug('socketio sent disconnect in jobs.events.annotation.service');

      _removeListeners(socket);
    });
  }

  function onLogOutOrDown() {
    jobTracker.clear();
    $rootScope.$broadcast(eventPrefix + events.clear);
  }

  function onLogIn() {
    //Cannot call jobTracker.clear() on every onLogIn call, because this may introduce
    // a race condition, in which jobTracker is being resolved in a route
    // and at the same time, that route may be resolved with no data at all
    //jobTracker.clear();
    // However, in order to allow routes to retry the request, in case
    // the call here failed for some reason, we need to call
    jobTracker.clear();
    jobTracker.initializeAsync().then(() => {
      $rootScope.$broadcast(eventPrefix + events.initialize, jobTracker.jobs);
    }, (err) => {
      $log.debug('jobTracker.initializeAsync rejected in loggedIn callback in jobs.events.service', err);
    });
  }
}